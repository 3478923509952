@media screen and (max-width: 768px) {
  .gx-container-wrap .gx-main-content-wrapper {
    width: 100vw !important;
    margin: 0 !important;
    padding: 0 10px !important;
    background-color: #fff;
  }

  .gx-main-content-wrapper {
    width: 100vw !important;
    margin: 0 !important;
    padding: 0 10px !important;
    background-color: #fff;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;  
}

input:-webkit-autofill{
    -webkit-text-fill-color: #545454 !important;
}

*:focus-visible {
  outline: none !important
}

.delete-profile-button {
  position: absolute;
  top: 60px;
  left: 60px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  visibility: hidden;
  
}

.profile-pic:hover .delete-profile-button {
  visibility: visible;
}

.custom-password input::placeholder {
  --tw-placeholder-opacity: 1;
color: rgba(107, 114, 128, var(--tw-placeholder-opacity))
}